import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { marketplaceName } from "../utils/defaults";
import {
	LanguageConfig,
	Marketplace,
} from "../utils/types";
import appier_ar from "./appier_ar";
import appier_ch from "./appier_ch";
import appier_en from "./appier_en";
import appier_jp from "./appier_jp";
import appier_kr from "./appier_kr";
import appier_tr from "./appier_tr";
import ar from "./ar";
import beymen_en from "./beymen_en";
import beymen_tr from "./beymen_tr";
import ch from "./ch";
import en from "./en";
import jp from "./jp";
import koctas_en from "./koctas_en";
import koctas_tr from "./koctas_tr";
import kr from "./kr";
import tr from "./tr";

const lang = navigator?.language?.slice(0, 2) === "tr" ? "tr" : "en";

const LANG_SELECTION = localStorage.getItem("retail_lang") || lang;

const asiaMarketplaces = ["appier", "mannings", "xebio", "airtrip"];

const getMarketplaceConfig = (
  baseLang: LanguageConfig,
  combinedLang: Record<Marketplace, LanguageConfig>
): LanguageConfig => {
  switch (marketplaceName) {
    case "beymen":
      return { ...baseLang, ...combinedLang.beymen };
    case "koçtaş":
      return { ...baseLang, ...combinedLang.koctas };
    default:
      return asiaMarketplaces.includes(marketplaceName)
        ? { ...baseLang, ...combinedLang.appier }
        : baseLang;
  }
};

const marketplaceTR = () =>
  getMarketplaceConfig(tr, {
    beymen: beymen_tr,
    koctas: koctas_tr,
    appier: appier_tr,
  });

const marketplaceEN = () =>
  getMarketplaceConfig(en, {
    beymen: beymen_en,
    koctas: koctas_en,
    appier: appier_en,
  });

const appierMarketplacesLang = (
  baseLang: LanguageConfig,
  combinedLang: LanguageConfig
) => {
  if (asiaMarketplaces.includes(marketplaceName)) {
    return { ...baseLang, ...combinedLang };
  } else return baseLang;
};

const marketplaceAR = () => appierMarketplacesLang(ar, appier_ar);
const marketplaceCH = () => appierMarketplacesLang(ch, appier_ch);
const marketplaceKR = () => appierMarketplacesLang(kr, appier_kr);
const marketplaceJP = () => appierMarketplacesLang(jp, appier_jp);

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: marketplaceEN(),
    },
    tr: {
      translation: marketplaceTR(),
    },
    ar: {
      translation: marketplaceAR(),
    },
    ch: {
      translation: marketplaceCH(),
    },
    jp: {
      translation: marketplaceJP(),
    },
    kr: {
      translation: marketplaceKR(),
    },
  },
  lng: LANG_SELECTION,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false,
  },
});

i18next.on("languageChanged", (lng: string) => {
  localStorage.setItem("retail_lang", lng);
});

export default i18next;
