import { ChangeEvent, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { localeLanguages } from "../../../../utils/helpers";
import { useInfiniteScroll } from "../../../../hooks/useInfiniteScroll";
import ConfigProvider from "antd/lib/config-provider";
import Divider from "antd/lib/divider";
import Table from "antd/lib/table";

import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import useApi from "../../../../api";
import RetailSearchBar from "../../../../components/Bar/RetailSearchBar";
import RetailEditableColumn from "../../../../components/Column/RetailEditableColumn";
import RetailBidEditContainer, {
  Selected,
} from "../../../../components/Container/RetailBidEditContainer";
import Empty from "../../../../components/Empty";
import RetailSettingsHeader from "../../../../components/Layout/RetailSettingsHeader";
import CategorySelect from "../../../../components/Select/CategorySelect";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import RetailPaginationButtons from "../../../../components/Button/RetailPaginationButtons";

import useMetricFetch from "../../../../hooks/useMetricFetch";
import useSettings from "../../../../hooks/useSettings";
import useTableFetch from "../../../../hooks/useTableFetch";

import cm from "../style.module.scss";

const RestrictionsPageCategoryTab = () => {
  const { t, i18n } = useTranslation();

  const { api } = useApi();

  const { data } = useSettings("MARKETPLACE");

  const { options, search, setSearch, handleScroll } =
    useInfiniteScroll("parent_categories");

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "minimum_bid":
      case "minimum_cpm":
      case "ACoS":
      case "relevance":
        return editableField({ value, records, field: col });

      default:
        return value ? value : "-";
    }
  };

  const { selectedColumns } = useMetricFetch(
    "restriction_settings_category",
    false,
    "",
    "category",
    renderColumns
  );

  const {
    data: selected_categories,
    changePageSize,
    config,
    setSort,
  } = useTableFetch("category-based-setting", false, { created_at: "desc" });

  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<Selected>({
    data: {},
    editing: false,
    editing_field: "",
    value: "",
  });

  const [dataSource, setDataSource] = useState<any[]>([]);

  const [visible, setVisible] = useState(false);

  const [currentCategory, setCurrentCategory] = useState<any>(undefined);

  useEffect(() => {
    setDataSource(
      selected_categories?.data?.records?.map((opt: any, index: number) => ({
        ...opt,
        index: index,
        minimum_bid: opt.minimum_bid || data?.data?.minimum_bid,
        minimum_cpm: opt.minimum_cpm || data?.data?.minimum_cpm,
        ACoS: opt.acos || data?.data?.targeting_acos,
        relevance: opt.relevance || data?.data?.general_category_relevance,
      }))
    );
  }, [selected_categories?.data, data?.data]);

  const editableField = ({ value, records, field }: any) => {
    const isFieldShouldBeEditable =
      selected?.data.id === records.id && selected.editing_field === field;

    const type = field === "acos" ? "percent" : "number";

    return isFieldShouldBeEditable ? (
      <RetailBidEditContainer
        selected={selected}
        setSelected={setSelected}
        onClick={changeColumn}
      />
    ) : (
      <RetailEditableColumn
        value={value}
        type={type}
        onClick={() => {
          setSelected({
            data: records,
            editing: true,
            value: records[field],
            editing_field: field,
          });
        }}
      />
    );
  };

  const deleteCategoryApi = async (id: number) => {
    try {
      await api.delete(`category-based-setting/${id}`);
      queryClient.refetchQueries("table");
    } catch (error) {
      console.log(error);
    }
  };

  const editableFieldKeys = ["minimum_bid", "minimum_cpm", "ACoS", "relevance"];

  const customizedKeys = selectedColumns(t).map((col) => col.dataIndex);

  const columns = (t: TFunction) => [
    {
      title: t("common.table.category_text"),
      dataIndex: "category_text",
      render: (value: string) => (value ? value : "-"),
    },
    ...editableFieldKeys.map((field: string) => ({
      title: t(`common.table.${field}`),
      dataIndex: field,
      render: (value: any, records: any) =>
        editableField({ value, records, field: field.toLowerCase() }),
    })),
  ];

  const action = [
    {
      title: "",
      dataIndex: "id",
      width: 5,
      render: (value: number) => (
        <DeleteOutlined
          className={cm.delete}
          onClick={() => deleteCategoryApi(value)}
        />
      ),
    },
  ];

  const updateColumn = async () => {
    const config = {
      [selected.editing_field]: parseFloat(selected.value),
      id: selected.data.category_id,
    };
    const response = await api.patch(
      `/category-based-setting/${selected.data.id}`,
      config
    );
    return response;
  };

  const { mutateAsync: mutateColumn } = useMutation(updateColumn);

  const changeColumn = async () => {
    await mutateColumn();
    queryClient.refetchQueries("table");
  };

  const openCategorySelect = () => setVisible(true);

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const postCategory = async (value: any) => {
    try {
      await api.post("category-based-setting", {
        category_id: value.id,
        minimum_bid: value.minimum_bid || data?.data?.minimum_bid,
        minimum_cpm: value.minimum_cpm || data?.data?.minimum_cpm,
        acos: value.acos || data?.data?.targeting_acos,
        relevance: value.relevance || data?.data?.general_category_relevance,
      });
      queryClient.refetchQueries("table");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    currentCategory && postCategory(currentCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const customizeRenderEmpty = () => <Empty type="categories_table" />;

  const close = () => {
    setSearch("");
    setVisible(false);
  };

  const categorySelectData = {
    options: options,
    type: "PARENT_CATEGORIES",
    close: close,
    handleScroll: handleScroll,
  };

  return (
    <>
      <RetailSettingsHeader type="category" />
      <div className={cm.container}>
        <div className={cm.flex}>
          <div>
            <RetailTitle level={4} className={cm.secondaryTitle}>
              {t("pages.admin.restrictions.categoryTitle")}
            </RetailTitle>
            <RetailText
              size="xxxs"
              family="poppins"
              className={cm.categoryText}
            >
              {t("pages.admin.restrictions.categoryText")}
            </RetailText>
          </div>
        </div>

        <Divider className={cm.divider} />
        <RetailSearchBar
          placeholder={t("components.searchBar.categories")}
          shadow
          value={search}
          onChange={handleSearch}
          onClick={openCategorySelect}
        />
        {visible && (
          <CategorySelect
            categoryData={categorySelectData}
            alreadySelectedData={dataSource}
            setCurrentCategory={setCurrentCategory}
          />
        )}
        <ConfigProvider
          renderEmpty={customizeRenderEmpty}
          locale={localeLanguages(i18n.language)}
        >
          <Table
            pagination={{
              current: config?.page,
              pageSize: config?.page_size,
              total: data?.data.total_records || data?.data.totalRecords,
              itemRender(page, type, element) {
                return (
                  <RetailPaginationButtons type={type} element={element} />
                );
              },
              showSizeChanger: true,
              showTotal: (total, range) => t("common.pageTotal"),
            }}
            sortDirections={["descend", "ascend", null]}
            onChange={(pagination, filters, sorter: any) => {
              sorter.order !== undefined && sorter.order !== null
                ? setSort({
                    sortValue: sorter.field,
                    order: sorter.order === "ascend" ? "asc" : "desc",
                  })
                : setSort(null);
              changePageSize(pagination.current!, pagination.pageSize!);
            }}
            scroll={{ x: true }}
            dataSource={dataSource}
            className={cm.table}
            columns={columns(t)
              .filter((column) => customizedKeys.includes(column.dataIndex))
              .concat(action)}
            rowKey="index"
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default RestrictionsPageCategoryTab;
