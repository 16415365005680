import { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Auth, AuthContext } from "../../../../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import RetailMainButton from "../../../../components/Button/RetailMainButton";
import RetailText from "../../../../components/Typography/RetailText";
import RetailOnboardingContainer from "../../../../components/Container/RetailOnboardingContainer";
import RetailOnboardingCountContainer from "../../../../components/Container/RetailOnboardingCountContainer";
import terms from "../../../../assets/terms/smo_terms.pdf";
import { ReactComponent as ArrowRightOutlined } from "../../../../assets/icons/arrowRightOutlined.svg";
import { ReactComponent as LinkedInFilled } from "../../../../assets/icons/linkedinFilled.svg";
import { ReactComponent as WavyUnderlineFilled } from "../../../../assets/icons/wavyUnderlineFilled.svg";
import img from "../../../../assets/images/onboarding/first-step.png";
import cc from "classcat";

import cm from "./style.module.scss";

const OnboardingFirstStep = () => {
  const { t } = useTranslation();

  const { authToken } = useContext(AuthContext) as Auth;

  const navigate = useNavigate();

  useEffect(() => {
    document.cookie = `linkedin_state_key=${uuidv4()}; path=/; Secure; SameSite=Lax`;
    if (authToken !== "") navigate("/login");
  }, [authToken, navigate]);

  const signInWithLinkedIn = () => {
    const origin = window.location.origin;
    window.location.href = `${origin}/auth/linkedin/authorize`;
  };

  return (
    <>
      <section className={cc(["flex", cm.container])}>
        <article className={cm.textContainer}>
          <RetailOnboardingCountContainer />
          <div className={cm.firstLine}>
            <RetailText weight="bold">
              {t("pages.auth.onboarding.login")}
            </RetailText>
            <RetailText weight="medium">
              {t("pages.auth.onboarding.to")}
            </RetailText>
            <RetailText weight="bold" className={cm.greenText}>
              {t("pages.auth.onboarding.retail")}
              <WavyUnderlineFilled className={cm.wavyUnderline} />
            </RetailText>
          </div>
          <RetailText className={cm.secondLine}>
            <Trans i18nKey="pages.auth.onboarding.infrastructure" />
          </RetailText>
          <RetailMainButton
            className={cm.linkedinBtn}
            hasBackground={true}
            onClick={signInWithLinkedIn}
            disabled={authToken !== ""}
          >
            <LinkedInFilled />
            <span> {t("pages.auth.onboarding.linkedInButton")}</span>
            <ArrowRightOutlined />
          </RetailMainButton>

          <RetailText size="xs" weight="medium" className={cm.secondaryText}>
            <Trans
              i18nKey="pages.auth.onboarding.linkedInTextAccept"
              components={{ 1: <Link to={terms} target="_blank" /> }}
            />
          </RetailText>
        </article>
      </section>
      <RetailOnboardingContainer
        text={t("pages.auth.onboarding.firstStepText")}
        sideImg={img}
        step="first"
      />
    </>
  );
};

export default OnboardingFirstStep;
