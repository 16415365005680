import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";

import { ReactComponent as EditOutlined } from "../../../assets/icons/editWhiteOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import Empty from "../../../components/Empty";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useTableFetch from "../../../hooks/useTableFetch";
import ReportDetailsInfoTab from "./ReportDetailsInfoTab";
import ReportDetailsResultTab from "./ReportDetailsResultTab";
import cm from "./style.module.scss";

const ReportDetailsPage = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [activeKey, setActiveKey] = useState("info");

  const handleKey = (key: string) => setActiveKey(key);

  const {
    data,
    isError,
    setSort,
    setIsExported,
    setFormat,
    changePageSize,
    isLoading,
  } = useTableFetch("query", false);

  const navigate = useNavigate();

  const admin = location.pathname.includes("admin");

  const redirect = () =>
    navigate(
      admin
        ? `/admin/edit-report/${data?.data.report}`
        : {
            pathname: `/edit-report/${data?.data.report}`,
            search: location.search,
          }
    );

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(
        () =>
          navigate(
            admin
              ? "/admin/reports"
              : { pathname: "/reports", search: location.search }
          ),
        100
      );
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <RetailPageContainer>
      {isLoading && activeKey === "info" ? (
        <Empty type="report_loading" className={cm.loading} />
      ) : (
        <>
          <header className={cm.header}>
            <RetailTitle level={2}>
              {data?.data.report_name ? data?.data.report_name : "-"}
            </RetailTitle>
            {data !== undefined && (
              <RetailMainButton
                hasBackground
                className={cm.btn}
                onClick={redirect}
              >
                <EditOutlined /> {t("common.edit")}
              </RetailMainButton>
            )}
          </header>

          <Row className="bordered-container">
            <Tabs
              className={cm.tabs}
              activeKey={activeKey}
              onChange={handleKey}
            >
              <Tabs.TabPane tab={t("components.reportForm.title")} key="info">
                <ReportDetailsInfoTab data={data} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t("pages.acc.reportDetails.result")}
                key="result"
              >
                <ReportDetailsResultTab
                  data={data}
                  setSort={setSort}
                  setIsExported={setIsExported}
                  setFormat={setFormat}
                  changePageSize={changePageSize}
                  isLoading={isLoading}
                />
              </Tabs.TabPane>
            </Tabs>
          </Row>
        </>
      )}
    </RetailPageContainer>
  );
};

export default ReportDetailsPage;
