import {
	useCallback,
	useLayoutEffect,
	useState,
} from "react";
import { TFunction } from "react-i18next";
import { useQuery } from "react-query";

import useApi from "../api";

const useMetricFetch = (
  url: string,
  isRelation: false,
  relationType: string,
  activeKey: string,
  renderColumns: any
) => {
  const { api, baseURL, adminInGeneral } = useApi();

  const displayName = useCallback(() => {
    if (isRelation) return `campaign_${relationType?.toLowerCase()}`;

    const isCheckup = activeKey?.includes("CHECKUP");

    if (isCheckup) return "campaigns_checkup";

    const isCouponsPage = window.location.pathname.includes("coupons");

    const isRestrictionsPage =
      window.location.pathname.includes("restrictions");

    const isAccountUsersPage = url === "account-users";

    const isInvitesPage = url === "invites";

    const isMarketplaceActive = activeKey === "MARKETPLACE";

    if (isCouponsPage) return "admin_coupons";

    if (isAccountUsersPage) {
      return isMarketplaceActive
        ? "access_users_admin"
        : "access_users_advertiser";
    }

    if (isInvitesPage) {
      return isMarketplaceActive
        ? "access_invited_admin"
        : "access_invited_advertiser";
    }

    if (isRestrictionsPage) return `restriction_settings_${activeKey}`;

    if (adminInGeneral) return `admin_${url}`;

    return `advertiser_${url}`;
  }, [isRelation, relationType, adminInGeneral, url, activeKey]);

  const [isEnabled, setIsEnabled] = useState(false);

  const [columns, setColumns] = useState<{
    (t: TFunction): any[];
  }>(() => (t: TFunction) => []);

  const [extraColumns, setExtraColumns] = useState<{
    (t: TFunction): any[];
  }>(() => (t: TFunction) => []);

  const [selectedColumns, setSelectedColumns] = useState<{
    (t: TFunction): any[];
  }>(() => (t: TFunction) => []);

  const [checkedValues, setCheckedValues] = useState([]);

  useLayoutEffect(() => {
    const isEnabled = baseURL() !== undefined && url !== "products";
    if (baseURL() !== undefined) setIsEnabled(isEnabled);
  }, [baseURL, isRelation, url]);

  const localStorageKey = `display_${
    adminInGeneral ? "admin" : "user"
  }_${url}_${isRelation ? "relation_" : "page"}${relationType || ""}`;

  const localStorageColumns = localStorage.getItem(localStorageKey);

  const filterLocalStorageColumns = useCallback(
    (cols: any) => {
      setSelectedColumns(
        () => (t: TFunction) =>
          cols(t).filter((item: any) => {
            return (
              JSON.parse(localStorageColumns!).indexOf(item.dataIndex) !== -1
            );
          })
      );
    },
    [localStorageColumns, setSelectedColumns]
  );

  useLayoutEffect(() => {
    if (localStorageColumns !== null)
      filterLocalStorageColumns((t: TFunction) => [
        ...columns(t),
        ...extraColumns(t),
      ]);
    else setSelectedColumns(() => (t: TFunction) => columns(t));
  }, [
    checkedValues,
    localStorageColumns,
    extraColumns,
    columns,
    filterLocalStorageColumns,
  ]);

  const generateTitle = (value: string, t: TFunction) => {
    switch (value) {
      case "CPC":
      case "CPM":
      case "RoAS":
        return t(`marketplaceDependentTable.${value}`);
      default:
        return t(`common.table.${value}`);
    }
  };

  const generateWidth = (value: string) => {
    switch (value) {
      case "status":
        return 100;
      case "match_type":
        return 200;
      case "bid":
      case "amount":
        return 125;
      case "image_url":
        return 300;
      default:
        return undefined;
    }
  };

  const configureColumns = (
    displayMappings: any[],
    isVisible: boolean,
    t: TFunction
  ) =>
    displayMappings
      .filter((item: any) => item?.is_default_visible === isVisible)
      .map((item: any) => ({
        title: generateTitle(item.value, t),
        dataIndex: item.value,
        sorter: item.value,
        key: item.value,
        width: generateWidth(item.value),
        render: (value: any, records: any) =>
          renderColumns ? renderColumns(item.value, value, records) : value,
      }));

  const onSuccess = (data: any) => {
    setColumns(
      () => (t: TFunction) =>
        configureColumns(data?.data?.display_mappings, true, t)
    );
    setExtraColumns(
      () => (t: TFunction) =>
        configureColumns(data?.data?.display_mappings, false, t)
    );
  };

  const { data, isLoading, isError, error } = useQuery(
    ["metric", { type: displayName() }],
    () => {
      const data = api.get("/displays", {
        params: {
          display_name: displayName(),
        },
      });

      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        onSuccess(data);
      },
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    selectedColumns,
    setSelectedColumns,
    columns,
    extraColumns,
    setExtraColumns,
    checkedValues,
    setCheckedValues,
  };
};

export default useMetricFetch;
