import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import cc from "classcat";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Auth, AuthContext } from "../../../context/AuthProvider";
import cm from "./style.module.scss";

export interface RetailPageContainerProps {
  children: React.ReactNode;
  loading?: boolean;
}

const RetailPageContainer = ({
  children,
  loading,
}: RetailPageContainerProps) => {
  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { pathname } = useLocation();

  const className = cc([
    cm.pageContainer,
    pathname === "/campaigns" || pathname === "/" ? cm.campaignsPage : "",
    showAdminBar ? cm.advertiserPage : "",
    pathname.includes("academy") ? cm.academyPage : "",
  ]);

  return (
    <>
      <Layout>
        <Spin spinning={!!loading}>
          <Row className={className}>
            <Col span={24}>{children}</Col>
          </Row>
        </Spin>
      </Layout>
    </>
  );
};

export default RetailPageContainer;
