import { useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, AuthContext } from "../../../context/AuthProvider";

import useOnboardingNavigate from "../../../hooks/useOnboardingNavigate";

import OnboardingFirstStep from "./OnboardingFirstStep";
import OnboardingSecondStep from "./OnboardingSecondStep";
import OnboardingThirdStep from "./OnboardingThirdStep";
import OnboardingFourthStep from "./OnboardingFourthStep";

import cm from "./style.module.scss";

const OnboardingPage = () => {
  const { authInfo } = useContext(AuthContext) as Auth;

  const { currentStep } = useOnboardingNavigate();

  const navigate = useNavigate();

  const account_index = JSON.parse(
    localStorage.getItem("account_index") || "0"
  );

  const handleRender = () => {
    if (currentStep > 4) return;
    switch (currentStep.toString()) {
      case "1":
        return <OnboardingFirstStep />;
      case "2":
        return <OnboardingSecondStep />;
      case "3":
        return <OnboardingThirdStep />;
      case "4":
        return <OnboardingFourthStep />;
      default:
        return <OnboardingFirstStep />;
    }
  };

  useLayoutEffect(() => {
    if (!authInfo || !account_index) return;
    if (authInfo[account_index]?.onboarding_status === "COMPLETE")
      navigate("/admin/campaigns");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo, account_index]);

  return <section className={cm.wrapper}>{handleRender()}</section>;
};

export default OnboardingPage;
