import { useContext, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { UploadProps, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import { ReactComponent as OnboardingCreativeUploadFilled } from "../../../../../assets/icons/onboardingCreativeUploadFilled.svg";
import RetailNotification from "../../../../../components/Notification";
import RetailText from "../../../../../components/Typography/RetailText";
import {
  CreativeContext,
  Creative,
} from "../../../../../context/CreativeProvider";
import { Img } from "../../../../../utils/types";

import cm from "./style.module.scss";

export interface OnboardingUploadContainerProps {
  advertiserID: number | undefined;
  onClick: () => void;
}

const OnboardingUploadContainer = ({
  advertiserID,
  onClick,
}: OnboardingUploadContainerProps) => {
  const { t } = useTranslation();
  const { setImg } = useContext(CreativeContext) as Creative;

  const mainImgFile = useRef<File>();

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;

    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name!);
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");
    formData.append("is_sample", "false");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        is_sample: formData.get("is_sample"),
        skip_size_validation: true,
      }),
    };

    try {
      await axios
        .post(`api/advertisers/${advertiserID}/creatives`, config, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("auth_token") || ""
            )}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const img = new Image();

          img.src = response.data.image_url;

          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
              },
            ]);
          };
        });
    } catch (err) {
      const maxFileSize = err?.request?.responseText?.includes(
        "file size is too large"
      );
      const errKey = maxFileSize ? "creativeSizeErr" : "generalErr";

      if (errKey) {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`components.campaignForm.firstStep.errorStates.${errKey}`)
        );
      }
      console.error(err);
    } finally {
      onClick();
    }
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: `dragger ${cm.uploadBanner}`,
    itemRender: () => <></>,
    beforeUpload: () => false,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .jpeg, .png",
  };

  return (
    <Upload.Dragger {...props}>
      <div className={`flex ${cm.innerUpload}`}>
        <OnboardingCreativeUploadFilled className={cm.upload} />
        <article>
          <RetailText size="xs" weight="medium" className={cm.uploadInnerTitle}>
            <Trans i18nKey="pages.auth.onboarding.uploadInnerTitle" />
          </RetailText>

          <RetailText
            size="xxxs"
            weight="medium"
            className={cm.uploadInnerText}
          >
            {t("pages.auth.onboarding.uploadInnerText")}
          </RetailText>
        </article>
      </div>
    </Upload.Dragger>
  );
};

export default OnboardingUploadContainer;
