import {
	Form,
	Radio,
} from "antd";
import cc from "classcat";
import { useState } from "react";
import {
	Trans,
	useTranslation,
} from "react-i18next";

import { ReactComponent as DeleteOutlined } from "../../../../../assets/icons/deleteOutlined.svg";
import { days } from "../../../../../utils/helpers";
import RetailMainButton from "../../../../Button/RetailMainButton";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface DayPartingProps {
  page: "form" | "details";
  isDayParting: boolean;
  isVisible: boolean;
  schedule: boolean[][];
  setSchedule: (schedule: boolean[][]) => void;
  handleFieldChange?(e: any): void;
}

const DayParting = ({
  page,
  isDayParting,
  isVisible,
  schedule,
  setSchedule,
  handleFieldChange,
}: DayPartingProps) => {
  const { t } = useTranslation();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [draggedHours, setDraggedHours] = useState<{ [key: string]: boolean }>(
    {}
  );

  const hours = Array.from({ length: 24 }, (_, i) => i);

  const toggleHour = (dayIndex: number, hourIndex: number) => {
    const newSchedule = schedule.map((day, dIndex) =>
      day.map((hour, hIndex) =>
        dIndex === dayIndex && hIndex === hourIndex ? !hour : hour
      )
    );
    setSchedule(newSchedule);
  };

  const toggleAllHours = (dayIndex: number) => {
    const allSelected = schedule[dayIndex].every((hour) => hour);
    const newSchedule = schedule.map((day, dIndex) =>
      dIndex === dayIndex ? day.map(() => !allSelected) : day
    );
    setSchedule(newSchedule);
  };

  const handleClear = () => {
    setSchedule(
      Array.from({ length: 7 }, () => Array.from({ length: 24 }, () => false))
    );
  };

  const handleMouseDown = (dayIndex: number, hourIndex: number) => {
    setIsMouseDown(true);
    toggleHour(dayIndex, hourIndex);
    setDraggedHours({ [`${dayIndex}-${hourIndex}`]: true });
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setDraggedHours({});
  };

  const handleMouseEnter = (dayIndex: number, hourIndex: number) => {
    if (isMouseDown) {
      const key = `${dayIndex}-${hourIndex}`;
      if (!draggedHours[key]) {
        toggleHour(dayIndex, hourIndex);
        setDraggedHours((prev) => ({ ...prev, [key]: true }));
      }
    }
  };

  return (
    <>
      {isVisible && (
        <>
          <section className={cc([cm.dayPartingCol, "flex", cm[page]])}>
            {page === "form" && (
              <div>
                <RetailTitle className={cm.title}>
                  {t("components.campaignForm.firstStep.dayParting")}
                </RetailTitle>
                <RetailText className={cm.text} size="xs" family="poppins">
                  {t("components.campaignForm.firstStep.dayPartingText")}
                </RetailText>
              </div>
            )}

            <Form.Item name="dayParting" className={cm[`${page}Radio`]}>
              <Radio.Group
                name="dayParting"
                onChange={handleFieldChange}
                className={cm.radioGroup}
              >
                <Radio
                  value="ALL_TIMES"
                  className={cc(["form-radio", cm.dayPartingRadio, cm[page]])}
                  data-test="campaign-form-targeting-auto"
                >
                  <RetailTitle level={5}>
                    {t("components.campaignForm.firstStep.allTimes")}
                  </RetailTitle>
                  <RetailText className={cm.dayPartingText} family="poppins">
                    {t("components.campaignForm.firstStep.allTimesText")}
                  </RetailText>
                </Radio>
                <Radio
                  value="SCHEDULED"
                  className={cc(["form-radio", cm.dayPartingRadio])}
                  data-test="campaign-form-targeting-auto"
                >
                  <RetailTitle level={5}>
                    {t("components.campaignForm.firstStep.scheduled")}
                  </RetailTitle>
                  <RetailText className={cm.dayPartingText} family="poppins">
                    {t("components.campaignForm.firstStep.scheduledText")}
                  </RetailText>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </section>
          {isDayParting && (
            <section className={cm.pickerContainer} onMouseUp={handleMouseUp}>
              <Trans>
                <RetailText
                  family="poppins"
                  size="xxxs"
                  className={cm.tableText}
                >
                  {t("components.campaignForm.firstStep.dayPartingTableText")}
                </RetailText>
              </Trans>

              <div className={cm.picker}>
                <div className={cm.header}>
                  <div className={cm.dayHeader}></div>
                  {hours.map((hour) => (
                    <div key={hour} className={cm.hourHeader}>
                      <span className={cc([cm.hour, cm[`hour${hour}`]])}>
                        {hour}
                      </span>
                    </div>
                  ))}
                </div>
                {days(t).map((day, dayIndex) => (
                  <div
                    key={day}
                    className={cc([cm.dayRow, cm[`day${dayIndex}`]])}
                  >
                    <div className={cm.dayNameContainer}>
                      <span className={cm.dayName}>{day}</span>
                    </div>
                    <div className={cm.dayRowContainer}>
                      <RetailMainButton
                        hasBackground={false}
                        className={cc([
                          cm.allDayBtn,
                          schedule[dayIndex].every((hour) => hour) &&
                            cm.selected,
                        ])}
                        onClick={() => toggleAllHours(dayIndex)}
                      >
                        {t("components.campaignForm.firstStep.allDay")}
                      </RetailMainButton>
                      {hours.map((hour, hourIndex) => (
                        <div
                          key={hour}
                          className={`${cm.hourBox} ${
                            schedule[dayIndex][hourIndex] ? cm.selected : ""
                          }`}
                          onMouseDown={() =>
                            handleMouseDown(dayIndex, hourIndex)
                          }
                          onMouseEnter={() =>
                            handleMouseEnter(dayIndex, hourIndex)
                          }
                        ></div>
                      ))}
                    </div>
                  </div>
                ))}
                {page === "form" && (
                  <div className={cm.clearContainer}>
                    <div className="flex" onClick={handleClear}>
                      <DeleteOutlined />
                      <RetailText
                        size="xxxs"
                        weight="medium"
                        className={cm.clearText}
                      >
                        {t("components.campaignForm.firstStep.clear")}
                      </RetailText>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default DayParting;
