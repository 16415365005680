import cc from "classcat";
import { useTranslation } from "react-i18next";

/* import { ReactComponent as ChatOutlined } from "../../../../assets/icons/chatOutlined.svg";
import useSettings from "../../../../hooks/useSettings"; */
import logo from "../../../../assets/images/logo.svg";
import { marketplaceName } from "../../../../utils/defaults";
import cm from "./style.module.scss";

const RetailAuthView = () => {
  const { t } = useTranslation();

  /*   const { data } = useSettings("ADVERTISER"); */

  const year = new Date().getFullYear();

  const bg =
    (window as any).marketplaceConfig.background_type?.toLowerCase() || "light";

  return (
    <div className={cc([cm.bg, cm[bg]])}>
      <img src={(window as any).marketplaceConfig.logo || logo} alt="logo" />
      <article>
        {/* <a href={`mailto:${data?.data?.email}`} className="flex">
          <ChatOutlined />
          <span>Destek Hattına Bağlan</span>
        </a> */}
        <h3 className={cm.title}>
          {t("pages.auth.title", { value: marketplaceName })}
        </h3>
        <p>
          {marketplaceName} {year} &copy;
        </p>
      </article>
    </div>
  );
};

export default RetailAuthView;
