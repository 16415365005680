import Drawer, { DrawerProps } from "antd/lib/drawer";
import Row from "antd/lib/row";
import { useTranslation } from "react-i18next";

import { ReactComponent as BlockFilled } from "../../../assets/icons/blockFilled.svg";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailDrawerProps extends DrawerProps {
  children: React.ReactNode;
  onOk: (data?: any) => void;
  mode?: "create" | "update" | null;
  type?: "add" | "edit" | "details" | "steps" | "products";
  disableOk?: boolean;
}

const RetailDrawer = ({
  children,
  onOk,
  mode,
  type,
  disableOk = false,
  title,
  onClose,
  width,
  ...drawerProps
}: RetailDrawerProps) => {
  const { t } = useTranslation();

  const close = (e: any) => onClose!(e);

  const isAdvertiserWarningVisible =
    window.location.pathname.includes("advertisers") && mode === "create";

  const switchText = () => {
    switch (type) {
      case "products":
        return t("common.selectAll");
      case "details":
        return t("common.okay");
      case "edit":
        return t("common.edit");
      case "steps":
        return t("common.next");
      case "add":
        return t("common.add");
      default:
        return t("common.save");
    }
  };

  return (
    <Drawer
      destroyOnClose
      closable
      width={width || 448}
      closeIcon={<CloseOutlined />}
      title={
        <RetailTitle level={5} className={cm.title}>
          {title}
        </RetailTitle>
      }
      onClose={close}
      footer={
        <Row
          className={`${cm.row} ${
            isAdvertiserWarningVisible ? cm.advertiser : ""
          } ${width && +width > 768 ? cm.biggerDrawer : ""}`}
        >
          {isAdvertiserWarningVisible && (
            <div className={`flex ${cm.advertiserWarning}`}>
              <BlockFilled />
              <RetailText size="xxs" weight="medium">
                {t("pages.admin.advertisers.drawerFilterWarning")}
              </RetailText>
            </div>
          )}
          {type !== "details" && (
            <RetailMainButton
              hasBackground={false}
              onClick={close}
              className={cm.cancelBtn}
            >
              {t("common.cancel")}
            </RetailMainButton>
          )}

          <RetailMainButton
            hasBackground
            onClick={onOk}
            className={`${cm.okBtn} ${
              type === "details" ? cm.secondaryOk : ""
            }`}
            disabled={disableOk}
          >
            {switchText()}
          </RetailMainButton>
        </Row>
      }
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};

export default RetailDrawer;
