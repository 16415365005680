import RetailAdvertiserViewLink from "../../Link/RetailAdvertiserViewLink";
import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";

import cm from "../style.module.scss";

export interface RetailLinkColumnProps {
  to: string;
  value: string;
  state: { breadcrumb: boolean; breadcrumbType: string; ad_type?: string };
}

const RetailLinkColumn = ({ to, value, state }: RetailLinkColumnProps) => {
  return (
    <>
      {value ? (
        <RetailAdvertiserViewLink
          to={to}
          className={cm.link}
          state={{ ...state, value: value }}
        >
          {value}
          <LinkOutlined className={cm.linkUp} />
        </RetailAdvertiserViewLink>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

export default RetailLinkColumn;
