import cc from "classcat";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Product,
  ProductContext,
} from "../../../../../context/ProductProvider";
import SelectProductsTable from "../../../../Table/SelectProductsTable";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

export interface SponsoredProductsProps {
  targeting: string;
  error: boolean;
  isBidColonVisible: boolean;
  ad_type: "PRODUCT" | "STORE" | "DISPLAY";
  advertiserStateForLinks: { id: number; name: string };
}

const SponsoredProducts = ({
  targeting,
  error,
  isBidColonVisible,
  ad_type,
  advertiserStateForLinks,
}: SponsoredProductsProps) => {
  const { t } = useTranslation();

  const { selectedProducts } = useContext(ProductContext) as Product;

  const colonNumber = () => {
    if (!isBidColonVisible && targeting === "AUTO_TARGETING") return "5";
    else if (isBidColonVisible && targeting !== "AUTO_TARGETING") return "7";
    else return "6";
  };

  return (
    <RetailFormRow
      className={cc([
        "form-row",
        cm.column,
        error && selectedProducts?.length === 0 ? cm.productError : "",
      ])}
    >
      <RetailFormInfo
        column={{
          number: colonNumber(),
          title: t(
            `components.campaignForm.firstStep.${ad_type?.toLowerCase()}Title`
          ),
          text: t(
            `components.campaignForm.firstStep.${ad_type?.toLowerCase()}Text`
          ),
        }}
        article={{
          text: t(`components.articles.${ad_type?.toLowerCase()}`),
          type: "product",
        }}
        advertiserStateForLinks={advertiserStateForLinks}
      />

      <SelectProductsTable ad_type={ad_type} fetchAll={false} error={error} />
    </RetailFormRow>
  );
};

export default SponsoredProducts;
