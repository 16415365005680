import { Input } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Upload from "antd/lib/upload";
import {
	UploadChangeParam,
	UploadFile,
	UploadProps,
} from "antd/lib/upload/interface";
import cc from "classcat";
import {
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
	DeleteOutlined,
	SearchOutlined,
} from "@ant-design/icons";

import useApi from "../../../../../api";
import { ReactComponent as UploadIcon } from "../../../../../assets/icons/draggerOutlined.svg";
import {
	Creative,
	CreativeContext,
} from "../../../../../context/CreativeProvider";
import useTableFetch from "../../../../../hooks/useTableFetch";
import { Img } from "../../../../../utils/types";
import Empty from "../../../../Empty";
import CreativePreviewModal from "../../../../Modal/CreativePreviewModal";
import RetailNotification from "../../../../Notification";
import RetailText from "../../../../Typography/RetailText";
import RetailCreativeUploadInfo from "../../../RetailCreativeUploadInfo";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

export interface CreativeStepProps {
  targeting: string;
  error: boolean;
  redirect_url: string;
  handleFieldChange(e: any): void;
  redirectURLError: boolean;
}

interface RecordType {
  recommended_size: string;
}

const CreativeStep = ({
  error,
  targeting,
  redirect_url,
  handleFieldChange,
  redirectURLError,
}: CreativeStepProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { img, setImg, deleteCreative } = useContext(
    CreativeContext
  ) as Creative;

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const [form] = useForm();

  const { data } = useTableFetch("ad_placements", false, {}, undefined, [
    { key: "status", op: "eq", value: "ACTIVE" },
    {
      key: "ad_format",
      op: "eq",
      value: "DISPLAY",
    },
  ]);

  const [uniqueRecommendedSizes, setUniqueRecommendedSizes] = useState<
    RecordType[]
  >([]);

  useEffect(() => {
    if (data?.data.records) {
      const sizeSet = new Set<string>();
      const uniqueSizes = data.data.records.filter((record: RecordType) => {
        if (!sizeSet.has(record.recommended_size)) {
          sizeSet.add(record.recommended_size);
          return true;
        }
        return false;
      });
      setUniqueRecommendedSizes(uniqueSizes);
    }
  }, [data]);

  const open = (url: string) => {
    setURL(url);
    setVisible(true);
  };

  const close = () => setVisible(false);

  const mainImgFile = useRef<File>();

  const props: UploadProps = {
    name: "files",
    multiple: true,
    className: "dragger",
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .svg, .jpeg, .png, gif",
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;
    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name.replace(/\.[^/.]+$/, "")!);
    formData.append("status", "ACTIVE");
    formData.append("format", "IMAGE");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
      }),
    };
    try {
      await api
        .post("/creatives", config, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          const img = new Image();

          img.src = response.data.image_url;

          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
              },
            ]);
          };
        });
    } catch (err) {
      const maxCreatives = err?.request?.responseText?.includes(
        "maximum number of creatives for SMO"
      );
      const maxFileSize = err?.request?.responseText?.includes(
        "file size is too large"
      );
      const errKey = maxCreatives
        ? "creativeLimitErr"
        : maxFileSize
        ? "creativeSizeErr"
        : "generalErr";

      if (errKey) {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`components.campaignForm.firstStep.errorStates.${errKey}`)
        );
      }
    }
  };

  const updateCreativeName = async (uuid: string) => {
    const response = await api
      .patch(`/creatives/${uuid}`, {
        name: form.getFieldValue(`${uuid}_name`),
      })
      .then((response) => {
        setImg((current: Img[]) =>
          current.map((obj: Img) => {
            if (obj.uuid === uuid) {
              return { ...obj, name: response.data.name };
            }
            return obj;
          })
        );
      });

    return response;
  };

  return (
    <div className={cm.creativeFormRow}>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "5" : "6",
            title: t("components.campaignForm.firstStep.creativeTitle"),
            text: t("marketplaceDependent.campaign_form_creative_text"),
          }}
        />
        <Upload.Dragger {...props}>
          <UploadIcon className={cm.upload} />
          <RetailText size="xs" weight="medium" className={cm.selectedTitle}>
            {t("components.campaignForm.firstStep.draggerTitle")}
          </RetailText>
          <RetailText size="xxxs" weight="medium" className={cm.creativeText}>
            {t("components.campaignForm.firstStep.draggerText")}
          </RetailText>
        </Upload.Dragger>
      </RetailFormRow>
      {img.length === 0 ? (
        <Empty type="creativeForm" className={cm.empty} isError={error}>
          {uniqueRecommendedSizes.map((d: RecordType) => (
            <RetailText
              key={d.recommended_size}
              size="xxxs"
              family="poppins"
              weight="medium"
              className={cm.sizeTag}
            >
              {d.recommended_size}
            </RetailText>
          ))}
        </Empty>
      ) : (
        <div className={cm.creativeSub}>
          {img.length > 0 && (
            <section className={cc(["flex", cm.creatives])}>
              {img.map((i) => (
                <div
                  className={cc([
                    "flex",
                    i.size_valid ? "" : cm.validError,
                    cm.creativeContainer,
                  ])}
                  key={i.uuid}
                >
                  <div className="flex">
                    <img src={i.url} alt={i.creative_name} />
                  </div>
                  <div>
                    <Form
                      onFinish={(e) => updateCreativeName(i.uuid)}
                      onFinishFailed={(e) => updateCreativeName(i.uuid)}
                      form={form}
                      autoComplete="off"
                      requiredMark={false}
                      initialValues={{ [`${i.uuid}_name`]: i.creative_name }}
                    >
                      <RetailFormInput
                        isFocused={i.creative_name !== ""}
                        label={t(
                          "components.campaignForm.firstStep.creativeLabel"
                        )}
                        className="floating"
                        name={`${i.uuid}_name`}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "components.campaignForm.firstStep.creativeError"
                            ),
                          },
                        ]}
                      >
                        <Input
                          className="floating"
                          data-test="campaign-form-creative-name"
                          onBlur={() => form.submit()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </RetailFormInput>
                    </Form>

                    <RetailText
                      weight="medium"
                      size="xs"
                      className={cc(["flex", cm.creativeContainerText])}
                    >
                      <span>{t("components.campaignForm.firstStep.size")}</span>
                      <span className={cm.size}>{i.size}</span>
                    </RetailText>
                    {i.size_valid ? (
                      <div className={cc(["flex", cm.icons])}>
                        <div
                          className={cc(["flex", cm.iconBtn])}
                          onClick={() => open(i.url)}
                        >
                          <SearchOutlined />
                        </div>
                        <div
                          className={cc(["flex", cm.iconBtn])}
                          onClick={() => deleteCreative(i.uuid)}
                        >
                          <DeleteOutlined className={cm.delete} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className={cc(["flex", cm.justDelete])}
                          onClick={() => deleteCreative(i.uuid)}
                        >
                          <DeleteOutlined className={cm.delete} />
                          <RetailText weight="bold" size="xs">
                            {t("common.delete")}
                          </RetailText>
                        </div>
                        <div className={cm.invalidTag}>
                          <RetailText
                            family="poppins"
                            weight="bold"
                            size="xxxs"
                          >
                            {t("components.campaignForm.firstStep.invalid")}
                          </RetailText>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </section>
          )}
        </div>
      )}

      <RetailCreativeUploadInfo fullWidth={img.length > 0} />

      <RetailFormRow className={cc(["form-row"])}>
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "6" : "7",
            title: t("components.campaignForm.firstStep.urlTitle"),
            text: t("components.campaignForm.firstStep.urlText"),
          }}
        />

        <RetailFormInput
          isFocused={redirect_url !== ""}
          name="url"
          label={t("components.campaignForm.firstStep.urlTitle")}
          rules={[
            {
              required: true,
              message: t("components.campaignForm.firstStep.urlError"),
              pattern:
                /^(https?:\/\/)?([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/,
            },
          ]}
          className="floating"
        >
          <Input.TextArea
            className={cc([
              "floating",
              cm.textarea,
              redirectURLError && redirect_url === "" ? cm.errorBorder : "",
            ])}
            data-test="campaign-form-redirect-url"
            onChange={handleFieldChange}
            name="redirect_url"
          />
        </RetailFormInput>
      </RetailFormRow>

      <CreativePreviewModal
        type="creative"
        url={url}
        visible={visible}
        onCancel={close}
      />
    </div>
  );
};

export default CreativeStep;
