import cc from "classcat";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as LeftCircleOutlined } from "../../../assets/icons/leftCircleOutlined.svg";
import { ReactComponent as ShopOutlined } from "../../../assets/icons/shopOutlined.svg";
import useSettings from "../../../hooks/useSettings";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

const RetailAdminBar = () => {
  const { t } = useTranslation();

  const { isLoading, data } = useSettings("ADMIN_VIEW");

  return (
    <div className={cc(["flex", cm.container])}>
      {!isLoading && (
        <div className={cc(["flex"])}>
          <RetailText weight="medium">{t("components.adminBar.rn")}</RetailText>
          <div className={cc(["flex", cm.advertiser])}>
            <ShopOutlined />
            <RetailText weight="bold">
              {data?.data?.name || "No Name"}
            </RetailText>
          </div>
          <RetailText weight="medium">
            {t("components.adminBar.visible")}
          </RetailText>
        </div>
      )}

      <Link to="/admin/campaigns" className={cc(["flex", cm.btn])}>
        <LeftCircleOutlined />
        <RetailText size="xs" weight="bold">
          {t("components.adminBar.back")}
        </RetailText>
      </Link>
    </div>
  );
};

export default RetailAdminBar;
